import { useSelector } from 'react-redux';
import { AMAZON_BASE_URL } from './Constants';
import { Store } from './types';

const TitleSection: React.FC<{ title: string }> = ({ title }) => {
    const storeConfiguration = useSelector(({ app }: Store) => app.storeConfiguration);

    if (!storeConfiguration) {
        return null;
    }

    return (
        <div className='title-section'>
            <h1>{title}</h1>
            <img
                src={`${AMAZON_BASE_URL}/store___${storeConfiguration.shortUniqueID}/template/separator_1.png`}
                alt='Underline'
            />
        </div>
    );
};

export default TitleSection;
