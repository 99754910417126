import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import TitleSection from './TitleSection';
import { Category, Store } from './types';
import { AMAZON_BASE_URL } from './Constants';

const Menu: React.FC<{
    title: string;
    categories: Category[];
    hash: string;
    backgroundImage: string;
    className?: string;
    link: string;
    text: string;
}> = ({ className, hash, title, backgroundImage, categories, link, text }) => {
    const products = React.useMemo(() => [], []);
    const allergens = useSelector(({ app }: Store) => app.allergens);
    const storeConfiguration = useSelector(({ app }: Store) => app.storeConfiguration);
    const [selectedCategory, setSelectedCategory] = React.useState<number>(null);
    const [filteredProducts, setFilteredProducts] = React.useState(products);

    const handleCategoryClick = React.useCallback(
        (id: number) => () => {
            setSelectedCategory(id);
            setFilteredProducts(
                products.filter(({ productCategoryID }) => productCategoryID === id)
            );
        },
        [products]
    );

    React.useEffect(() => {
        const categoryId = categories[0]?.id;

        if (!_.isNil(categoryId)) {
            handleCategoryClick(categoryId)();
        }
    }, [categories, products, handleCategoryClick]);

    if (!storeConfiguration) {
        return null;
    }

    return (
        <div
            className={classNames('restaurant-menu', className)}
            id={hash}
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center bottom',
                backgroundSize: 'cover'
            }}>
            <div className='container'>
                <TitleSection title={title} />
                <div>
                    <FontAwesome
                        name='file-pdf-o'
                        style={{ marginRight: 5 }}
                        className='download-pdf'
                    />
                    <a
                        href={`${AMAZON_BASE_URL}/store___${storeConfiguration.shortUniqueID}/downloads/${link}`}
                        target='_blank'
                        rel='noreferrer'
                        style={{ fontSize: 20 }}>
                        Download hier onze {text}
                    </a>
                </div>
                <div className='categories'>
                    {categories.map(({ id, name }) => (
                        <div
                            className={classNames('category', {
                                selected: selectedCategory === id
                            })}
                            key={id}
                            onClick={handleCategoryClick(id)}>
                            {name}
                            <div />
                        </div>
                    ))}
                </div>
                <div className='menu-products'>
                    {_.chunk(
                        filteredProducts,
                        Math.ceil(filteredProducts.length / 2)
                    ).map((value, i) => (
                        <div className='product-chunk' key={i}>
                            {value.map(
                                ({
                                    name,
                                    id,
                                    description,
                                    productAllergens,
                                    price,
                                    isVegan,
                                    isVegetarian,
                                    productVarieties
                                }) => {
                                    const allergenCodes = productAllergens
                                        .map(
                                            ({ allergenID }) =>
                                                allergens.find(a => a.id === allergenID)
                                                    ?.code
                                        )
                                        .filter(c => !!c);

                                    const productPrice = productVarieties.length
                                        ? productVarieties.map(v => v.price).join('/')
                                        : price;

                                    const hasSinglePrice =
                                        !!categories.find(
                                            c => c.id === selectedCategory
                                        )?.productCategoryMiscInfo?.isWine ||
                                        !productVarieties.length;

                                    return (
                                        <div key={id} className='product'>
                                            <div
                                                className={classNames('left', {
                                                    full: !hasSinglePrice
                                                })}>
                                                <span className='description'>
                                                    {name} {description}
                                                </span>
                                                {!!allergenCodes.length && (
                                                    <span className='allergens'>
                                                        | {allergenCodes.join('/')}
                                                    </span>
                                                )}
                                                <div className='type'>
                                                    <span>
                                                        {isVegetarian && 'vegetarisch'}
                                                    </span>
                                                    <span>{isVegan && 'vegan'}</span>
                                                </div>
                                                {!hasSinglePrice && (
                                                    <div>
                                                        {productVarieties.map(p => (
                                                            <div
                                                                key={p.id}
                                                                className='variety'>
                                                                <span>{p.name}</span>
                                                                <div className='right'>
                                                                    € {p.price}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                            {hasSinglePrice && (
                                                <div className='right'>
                                                    € {productPrice}
                                                </div>
                                            )}
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Menu;
