import React from 'react';
import { last } from 'lodash';
import { useSelector } from 'react-redux';
import Lightbox from 'react-image-lightbox';
import {
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton
} from 'react-share';
import { AMAZON_BASE_URL, MENUS } from './Constants';
import TitleSection from './TitleSection';
import { AppStore, Store } from './types';

const Gallery: React.FC = () => {
    const galleryImages = useSelector(({ app }: Store) => app.galleryImages);
    const storeConfiguration = useSelector(({ app }: Store) => app.storeConfiguration);

    const [open, setOpen] = React.useState(false);
    const [selectedImage, setSelectedImage] =
        React.useState<AppStore['galleryImages'][number]>();

    const handleImageClick = React.useCallback(
        (image: AppStore['galleryImages'][number]) => () => {
            setOpen(true);
            setSelectedImage(image);
        },
        []
    );

    const handleLightboxClose = React.useCallback(() => {
        setOpen(false);
        setSelectedImage(null);
    }, []);

    const getImageURL = React.useCallback(
        (imageName: string) =>
            `${AMAZON_BASE_URL}/store___${storeConfiguration.shortUniqueID}/websiteGallery/${imageName}`,
        [storeConfiguration?.shortUniqueID]
    );

    const getImageUrl = React.useCallback(
        (imageName: string, size: 'sml' | 'normal'): string => {
            if (!imageName) {
                return '';
            }

            const [name] = imageName.split('.');
            const extension = last(imageName.split('.'));
            return getImageURL(`${name}${size === 'sml' ? '_sml' : ''}.${extension}`);
        },
        [getImageURL]
    );

    if (!storeConfiguration) {
        return null;
    }

    const caption = (
        <div className='share-icons'>
            <TwitterShareButton url={selectedImage?.name} className='icon-share'>
                <TwitterIcon size={30} round className='icon' />
                Share
            </TwitterShareButton>
            <FacebookShareButton url={selectedImage?.name} className='icon-share'>
                <FacebookIcon size={30} round className='icon' /> Post
            </FacebookShareButton>
        </div>
    );

    return (
        <div id={MENUS.GALLERY.hash} className='gallery-section'>
            <div className='container'>
                <TitleSection title='Galerij' />
                <div className='content'>
                    {galleryImages.map(image => (
                        <figure key={image.id} onClick={handleImageClick(image)}>
                            <img src={getImageUrl(image.name, 'sml')} />
                        </figure>
                    ))}
                </div>
            </div>
            {open && (
                <Lightbox
                    mainSrc={getImageUrl(selectedImage?.name, 'normal')}
                    onCloseRequest={handleLightboxClose}
                    imageCaption={caption}
                />
            )}
        </div>
    );
};

export default Gallery;
