import { BASE_URL, getUpdatedClosure, SAO_BASE_URL } from './Constants';
import { Category, Product, Allergen, AppStore, Closure } from './types';

const getWithToken = (url: string, token: string) =>
    fetch(`${SAO_BASE_URL}/${url}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    }).then(res => {
        if (res.status === 200) {
            return res.json();
        }

        return res.text().then(err => {
            throw Error(
                `Ein Fehler passiert... Code: ${res.status}${
                    err ? `, Message: ${err}` : ''
                }`
            );
        });
    });

export const registerToNewsletter = (values: { fullName: string; email: string }) =>
    fetch(`${BASE_URL}/RegisterToNewsletter`, {
        method: 'POST',
        body: JSON.stringify({
            ...values,
            storeSecretID: 'anything'
        }),
        headers: { 'Content-Type': 'application/json' }
    });

export const login = (): Promise<{ token: string }> =>
    fetch(`${SAO_BASE_URL}/Account/Login`, {
        method: 'POST',
        body: JSON.stringify({
            username: 'lgkiourou',
            password: 'Oav4zaif#',
            storeID: 30
        }),
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.status === 200) {
            return res.json();
        }

        return res.text().then(err => {
            throw Error(
                `Ein Fehler passiert... Code: ${res.status}${
                    err ? `, Message: ${err}` : ''
                }`
            );
        });
    });

export const reserveTable = (token: string, values: Record<string, unknown>) =>
    fetch(`${SAO_BASE_URL}/Reservations/AddNew`, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    }).then(res =>
        res.text().then(err => {
            if (res.status === 200) {
                return;
            }

            throw Error(
                `Ein Fehler passiert... Code: ${res.status}${
                    err ? `, Message: ${err}` : ''
                }`
            );
        })
    );

export const getCategories = (token: string): Promise<Category[]> =>
    getWithToken('ProductCategories', token);

export const getProducts = (token: string): Promise<Product[]> =>
    getWithToken('Products', token);

export const getAllergens = (token: string): Promise<Allergen[]> =>
    getWithToken('Allergens', token);

export const getGalleryImages = (token: string): Promise<AppStore['galleryImages']> =>
    getWithToken('GalleryImages ', token);

export const getStoreConfiguration = (
    token: string
): Promise<AppStore['storeConfiguration']> => getWithToken('StoreConfiguration', token);

export const contact = (values: Record<string, string>) =>
    login().then(({ token }) =>
        fetch(`${SAO_BASE_URL}/Misc/ContactStore`, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(res =>
            res.text().then(err => {
                if (res.status === 200) {
                    return;
                }

                throw Error(
                    `Ein Fehler passiert... Code: ${res.status}${
                        err ? `, Message: ${err}` : ''
                    }`
                );
            })
        )
    );

export const getClosures = (token: string): Promise<Closure[]> =>
    getWithToken('WorkingHours/GetClosures', token).then(
        (closures: Closure<string>[]) => closures.map(getUpdatedClosure)
    );
